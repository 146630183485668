@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import '~antd/dist/antd.css'; */
.ant-color-picker-inner-content {
	width: auto !important;
}

.layout-container-margin {
	margin-left: 202px !important;
}

.circle-profile {
	border-radius: 50%;
}

.semicircle-container svg {
	margin-left: auto;
	margin-right: auto;
}

.layout-header-margin {
	margin-left: 201px !important;
	width: calc(100vw - (100vw - 100%) - calc(2.2rem * 2) - 130px) !important;
}

.ant-collapse-header {
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}

.ant-input-group-addon {
	padding: 0px !important;
}

.ant-table-wrapper {
	padding: 0px 6px;
}

.anticon {
	vertical-align: 0;
}

.login-bg {
	background: #28693fe0;
	height: 100vh;
}

.report-style-class iframe {
	height: 44vh !important;
	border: none !important
}

.br-6 {
	border-radius: 6px;
}

/* @layer components {
    .breadcrumb {
        @apply text-[#788896] text-sm font-medium items-center p-0 hover:text-black
    }
    .breadcrumb-current {
        @apply text-mainThemeColor text-sm font-medium items-center p-0
    }
} */

.form-item-multiple-extras .ant-form-item-extra {
	background-color: red;
	white-space: pre-wrap;
}

/* Custom message table */

.custom-message-table table,
.custom-message-table table tr,
.custom-message-table table td,
.custom-message-table table th {
	border: 1px solid black;
}

.custom-message-table .ant-pagination {
	float: left;
}

.custom-message-table .ant-pagination .ant-pagination-total-text {
	display: flex;
	order: 2;
	font-weight: 600;
	padding-left: 1rem;
}

.custom-radio {
	margin-right: 0;
}

.custom-radio> :nth-child(2) {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-right: 0;
}

.ant-input-group-addon:first-child {
	padding: 4px 11px !important;
}


.country-name-display-flex {
	display: inline-flex;
	gap: 6px;
}

.country-name-text-nowrap {
	white-space: nowrap;
}


.color-G {
	padding: 36px;
	color: green;
}

.color-R {
	padding: 36px;
	color: red;
}

.color-N {
	padding: 36px;
}

.networkLoading {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.7;
	background-color: #fff;
	z-index: 99;
}

.networkLoading>span {
	z-index: 100;
}

.networkLoading>div {
	padding-left: 20px;
}

.DraftEditor-root {
	font-family: 'Poppins';
}