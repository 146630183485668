.toolbarClassName {
    background-color: rgb(224, 220, 220);
}
.rdw-block-wrapper, 
.rdw-text-align-wrapper, 
.rdw-colorpicker-wrapper, 
.rdw-emoji-wrapper,
.rdw-remove-wrapper,
.rdw-embedded-wrapper, .rdw-image-wrapper,
.rdw-fontfamily-wrapper,
rdw-fontsize-wrapper,
.rdw-link-wrapper,
.rdw-option-disabled,
.rdw-fontsize-wrapper {
    /* display: none; */
}

/* .wrapperClassName {
    background-color: blue;
}  */

.editorClassName {
    border: 1px solid #C5CED6;
    height: 200px;
}

/* ******************** */

/* .jodit-toolbar__box {
    position: relative;
    z-index: 0 !important;
}

.jodit-toolbar-button_fullsize,
.jodit-toolbar-button_about,
.jodit-toolbar-button_brush,
.jodit-toolbar-button_eraser,
.jodit-toolbar-button_link,
.jodit-ui-group_group_insert,
.jodit-ui-group_group_clipboard,
.jodit-ui-group_group_search,
.jodit-ui-group_group_source,
.jodit-ui-group_group_media,
.jodit-toolbar-button_classSpan,
.jodit-toolbar-button_lineHeight,
.jodit-status-bar,
.jodit-ui-spacer {
    display: none !important;
    position: relative;
    z-index: 0;
}

.jodit-toolbar-editor-collection {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    background-color: rgb(224, 220, 220);
} */
/* .jodit-ui-group_group_other, */